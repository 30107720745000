import React, { useEffect, useState } from "react";

import UpperMenu from "../components/UpperMenu.js"
import { Navigate } from "react-router-dom";
import dbQuery from "../gets/DBQuery.js";
import ChatSelectButton from "../components/ChatSelectButton.js";

const Chats = () => {

    var loadingChats = <ChatSelectButton chat={{demand_title:"Carregando..."}}/>;

    const [chats, setChats] = useState([loadingChats]);

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    var user_id = localStorage.getItem("user_id");

    useEffect(() => {
        //get demands from db
        dbQuery("get_demands_by_user_id_with_sender_username", {
            demand_title: "",
            demand_description: "",
            demand_theme: ""
        }, (data)=>{
            var new_chats = [];
            for(var i = 0; i < data.length; i++){
                var chat = data[i];
                new_chats.push(<ChatSelectButton chat={chat}/>);
            }
            if(new_chats.length === 0){
                new_chats.push(<ChatSelectButton chat={{demand_title:"Nenhuma conversa encontrada."}}/>);
            }
            setChats(new_chats);
        });
    }, []);

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
        <div className="vh-100 flex-column">
            <UpperMenu owner={true}/>
            <div className="d-flex center-content flex-grow-v">
                <div className="default-page-content-size h-100">
                    <div className="chat-page-container flex-column h-100 relative">
                        <div className="bg-white z-index-n1 chat-container overflow-y-auto absolute h-100 w-100">
                        </div>
                        <div className="chat-container overflow-y-auto px-2">
                            <div className="current-chat flex-column h-100">
                                <div className="pt-2">
                                    <div className="chat-messages chats-height">
                                        <div className="pt-2"></div>
                                        {chats}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Chats;