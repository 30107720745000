import React from "react";

import UpperMenu from "../components/UpperMenu.js"
import Profile from "../components/Profile.js"
import { Navigate } from "react-router-dom";

const Home = () => {

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    var profileOwner = JSON.parse(localStorage.getItem("councilor_app_id"));

    return (
        <div>
            <UpperMenu/>
            <div className="d-flex center-content">
                <Profile profileOwner={profileOwner} message={"Faça a confirmação da sua conta."}/>
            </div>
        </div>
    )
};

export default Home;