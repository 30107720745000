import React from "react";
import PageForm from "../components/PageForm";
import UpperMenu from "../components/UpperMenu";
import Form from "../components/Form";
import { Navigate } from "react-router-dom";
import newDemandForm from "../components/Forms/newDemandForm";
import dbQuery from "../gets/DBQuery";

const DemandAdd = () => {

    const handleDemand = (event) => {
        event.preventDefault();

        var {title, description, theme} = document.getElementsByClassName("new-demand-form")[0];
        
        var demand = {
            demand_description: description.value,
            demand_theme: theme.value,
            demand_title: title.value,
            demand_date: new Date().toISOString(),
            id_receiver: JSON.parse(localStorage.getItem("councilor_app_id")),
        }

        dbQuery("insert_demand", demand, (data)=>{
            alert("Demanda enviada com sucesso!");
            window.location.reload();
        });
    }

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    var inputs = newDemandForm;

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
        <div>
            <UpperMenu/>
            <div className="default-container">
                <div className="p-10">
                    <PageForm title={"Nova demanda ou ocorrência"} content={<Form className="new-demand-form" formFunction={handleDemand} inputs={inputs} submitButton="Enviar"/>}/>
                </div>
            </div>
        </div>
    )
};

export default DemandAdd;