import React, { useEffect, useRef, useState } from "react";

import UpperMenu from "../components/UpperMenu.js"
import { Navigate, useParams } from "react-router-dom";
import ChatMessage from "../components/ChatMessage.js"
import dbQuery from "../gets/DBQuery.js"

const Chat = () => {

    //get demand_id from url
    var { demand_id } = useParams();

    const [demand_title, setDemandTitle] = useState("Carregando...");
    const [messages, setMessages] = useState([]);

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    const addImg = () => {
        document.getElementById("media").click();
    }

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView()
    }
    
    useEffect(() => {
        scrollToBottom()
    }, [messagesEndRef]);

    useEffect(() => {
        //get messages from db
        dbQuery("get_messages_by_demand_id_with_sender_username", {
            id_demand: demand_id
        }, (data)=>{
            var new_messages = [];
            for(var i = 0; i < data.length; i++){
                var message = data[i];
                new_messages.push(<ChatMessage senderName={message.sender_username} message_text={message.encrypted_message}/>);
            }
            setMessages(new_messages);
        });
        dbQuery("get_demand_by_id", {
            id_demand: demand_id
        }, (data)=>{
            setDemandTitle(data[0].demand_title);
        });
    }, []);

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    if(demand_id == null){
        return <Navigate to="/chats"/>;
    }

    const handleMessageSubmit = (e) => {
        e.preventDefault();
        
        var { message } = document.getElementsByClassName("chat-input")[0];

        //send message to db
        dbQuery("insert_message", {
            encrypted_message: message.value,
            id_demand: demand_id
        }, (data)=>{
            if(typeof data === "boolean" && !data){
                alert("Erro ao enviar mensagem");
            }
            else{
                window.location.reload();
            }
        });
    }

    return (
        <div className="vh-100 flex-column">
            <UpperMenu owner={true}/>
            <div className="d-flex center-content flex-grow-v">
                <div className="default-page-content-size h-100">
                    <div className="chat-page-container flex-column h-100">
                        <div className="header-container default-border-bottom">
                            <div className="bg-white z-index-n1 px-4 py-3 bold one-line">
                                {demand_title}
                            </div>
                        </div>
                        <div className="bg-white chat-container overflow-y-auto px-2">
                            <div className="current-chat flex-column h-100">
                                <div className="default-border-bottom">
                                    <div className="chat-messages chat-messages-height">
                                        <div className="pt-2"></div>
                                        {messages}
                                        <div ref={messagesEndRef}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white chat-input-container px-2">
                            <div className="chat-input-content default-border-top">
                                <form className="chat-input pt-1 pb-1 px-2 d-flex" onSubmit={handleMessageSubmit}>
                                    {/* <input id="media" className="v-hidden" type="file"/>
                                    <div className="post-media p-2 d-flex align-items-center">
                                        <img className="icon mouse-pointer-hover" src={ImgIcon} alt="Imagem" onClick={addImg} name="media"/>
                                    </div> */}
                                    <div className="p-2 d-flex align-items-center w-100">
                                        <textarea className="px-2 py-1 w-100 small-default-border-radius" type="text" placeholder="Digite uma mensagem" name="message"/>
                                    </div>
                                    <div className="p-2 d-flex align-items-center">
                                        <input type="submit" className="small-form-btn" value="Enviar"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Chat;