import React, { useEffect, useRef, useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import Form from "../components/Form.js";
import SearchPage from "../components/SeachPage.js";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridDay from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import EventForm from "../components/Forms/EventForm.js";
import PageForm from "../components/PageForm.js";
import UpperMenu from "../components/UpperMenu.js";
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
import getEvents from "../gets/GetEvents.js";
import defaultSearch from "../components/DefaultSearchConstructor.js";
import handleNewEvent from "../sends/newEvent.js";

const EventsSchedule = (props) => {

    var councilor_app_id = localStorage.getItem("councilor_app_id");
    var user_id = JSON.parse(localStorage.getItem("user_id"));

    var isOwner = councilor_app_id == user_id;

    const navigate = useNavigate();

    const search = defaultSearch("search-page-form", (query)=>navigate("/schedule_search/"+query));

    const {query} = useParams();

    const search_query = query && query.includes("name=") ? query.split("name=")[1].split("&")[0] : undefined;
    const start = query && query.includes("start=") ? query.split("start=")[1].split("&")[0] : undefined;
    const end = query && query.includes("end=") ? query.split("end=")[1].split("&")[0] : undefined;

    var inputs = {
        1:{
            placeholder:"Termos da pesquisa", type:"textarea", name:"name"
        },
        2:{
            placeholder:"Desde", type:"date", name:"start"
        },
        3:{
            placeholder:"Até", type:"date", name:"end"
        },
        4:{
            btn:true, type:"submit", value:"Pesquisar", required:true
        },
    };

    var inputsEvent = EventForm;

    var newEvent = isOwner &&
    <div className="default-container">
        <div className="px-5 pt-5">
            <PageForm title={"Novo evento"} content={<Form className={"new-event-form"} formFunction={handleNewEvent} inputs={inputsEvent} withImg={true} submitButton="Enviar"/>}/>
        </div>
    </div>;

    var search_db_query = {
        schedule_title: search_query,
        start: start,
        end: end
    };

    const [events, setEvents] = useState([]);

    useEffect(()=>{
        getEvents(search_db_query, (events)=>{
            setEvents(events);
        });
    }, [query]);

    const [view, setView] = useState("dayGridMonth");
    const calendarRef = useRef(null);

    useEffect(() => {
        const { current: calendarDom } = calendarRef;
        const API = calendarDom ? calendarDom.getApi() : null;
        API && API.changeView(view);
    }, [view]);

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    // if(!loggedIn){
    //     return <Navigate to="/login"/>;
    // }

    return (
        <div>
            <UpperMenu/>
            {newEvent}
            <SearchPage form={<Form className={"search-page-form"} formFunction={search} inputs={inputs} submitButton="Pesquisar"/>} title={"Agenda"}/>
            <div className="pb-4"></div>
            <div className="d-flex center-content w-100">
                <div className="default-page-content-size">
                    <div className="px-4 pb-4">
                        <div className="px-3 smaller-title-text bg-white pt-3 default-border-radius pb-3">
                            <FullCalendar
                                locale={ptbrLocale}
                                initialView={view}
                                events={events}
                                plugins={[dayGridPlugin, interactionPlugin, timeGridDay]}
                                ref={calendarRef}
                                headerToolbar={{
                                    left: "title",
                                    right: "today day,month prev,next",
                                  }}
                                  customButtons={{
                                    day: {
                                      text: "Dia",
                                      click: function () {
                                        setView("timeGridDay")
                                      }
                                    },
                                    month: {
                                      text: "Mês",
                                      click: function () {
                                        setView("dayGridMonth")
                                      }
                                    }
                                  }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-4"></div>
        </div>
    )
};

export default EventsSchedule;