import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./routes/Home.js";
import Login from "./routes/Login.js";
import ModifyProfile from "./routes/ModifyProfile.js";
import Registration from "./routes/Registration.js";
import ProfilePage from "./routes/ProfilePage.js";
import PropositionSearch from "./routes/PropositionSearch.js";
import DemandAdd from "./routes/DemandAdd.js";
import Demands from "./routes/Demands.js";
import EventsSchedule from "./routes/EventsSchedule.js";
import Notifications from "./routes/Notifications.js";
import Chat from "./routes/Chat.js";
import Chats from "./routes/Chats.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/home",
    element: <Home/>
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/modify_profile",
    element: <ModifyProfile/>
  },
  {
    path: "/registration",
    element: <Registration/>
  },
  {
    path: "/proposition_search/:query",
    element: <PropositionSearch/>
  },
  {
    path: "/proposition_search",
    element: <PropositionSearch/>
  },
  {
    path: "/profile/:user",
    element: <ProfilePage/>
  },
  {
    path: "/profile",
    element: <ProfilePage/>
  },
  {
    path: "/demand",
    element: <DemandAdd/>
  },
  {
    path: "/demands",
    element: <Demands/>
  },
  {
    path: "/demands/:query",
    element: <Demands/>
  },
  {
    path: "/schedule_search",
    element: <EventsSchedule/>
  },
  {
    path: "/schedule_search/:query",
    element: <EventsSchedule/>
  },
  {
    path: "/notifications",
    element: <Notifications/>
  },
  {
    path: "/chat/:demand_id",
    element: <Chat/>
  },
  {
    path: "/chats/",
    element: <Chats/>
  },
  // {
  //   path: "*",
  //   element: <NotFound/>
  // },
])

const Router = (props) => {

    var auth = JSON.parse(localStorage.getItem("auth"));

    if (auth === null || auth === undefined) {
        localStorage.setItem("auth", JSON.stringify({"token": undefined, "user_id": undefined}));
        localStorage.setItem("user_id", JSON.stringify(null));
    }

    localStorage.setItem("councilor_app_id", JSON.stringify(1));

    return(
        <RouterProvider router={router} />
        /*<React.StrictMode>
            
        </React.StrictMode>*/
    )
}

export default Router;